import { Injectable, Inject, InjectionToken, Injector } from '@angular/core';

import * as moment_ from 'moment-timezone';
const moment = moment_;
import {
  Subject,
  Observable,
  throwError,
  of,
  forkJoin,
  BehaviorSubject,
} from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import {
  User,
  Notification,
  Account,
  UserFavoriteType,
  POD_MASTER_ACCOUNT_ID,
} from '../models';
import { ApiList, ApiService } from './api.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RouterEvent } from '@angular/router';

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token';
export const TOKEN_QUERY_KEY = 'access_token';
export const USE_LANGS = new InjectionToken<Array<string>>('USE_LANGS');
export const LAST_LOCATION_STORAGE_KEY = 'last_location';

export interface ILoginCustomization {
  loginPageMessage: string;
  logoLogin: string;
  theme: string;
  footer: boolean;
  whitelabel: boolean;
  favicon: string;
}

export interface IAccountTreeItem {
  name: string;
  id: string;
  children: Array<IAccountTreeItem>;
}
export interface CommercialReferenceAccount {
  name: string;
  id: string;
  customerNumber: string;
}

export interface IAuthTokenResponseLogged {
  token: string;
  user: User;
}

export enum I2faType {
  SMS = 'sms',
}

export enum I2faResponseCode {
  TWOFA_SET = '2fa-set',
  TWOFA_CONFIRM = '2fa-confirm',
}
export interface I2faResponseLink {
  link: string;
  method: string;
}
export interface I2faResponse {
  message: string;
  code: I2faResponseCode;
  type: I2faType;
  token: string;
  expirationTime: number;
  next: I2faResponseLink;
}

export type IAuthTokenResponse = IAuthTokenResponseLogged | I2faResponse;

export interface ILoginResult {
  code: 'logged' | I2faResponseCode;
  type?: I2faType;
  token?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public user: User = null;
  public account$ = new BehaviorSubject<Account>(null);
  public get account() {
    return this.account$.value;
  }
  public set account(newAccount: Account) {
    this.account$.next(newAccount);
  }

  public accountsMap: Record<string, Account>;
  public cmAccountsMap: Account[];

  protected hasFavoritesSubjects: { [key: string]: Subject<boolean> } = {};
  protected unreadNotificationsSubject: Subject<ApiList<Notification>>;
  protected permissionsMap: { [id: string]: boolean } = {};

  private _api: ApiService;
  private get api() {
    if (!this._api) {
      this._api = this.injector.get(ApiService);
    }
    return this._api;
  }

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private injector: Injector,
    @Inject(USE_LANGS) protected useLangs: Array<string>
  ) {}

  public getTimezone(): string {
    if (
      this.user &&
      this.user.profile &&
      this.user.profile.timezone &&
      moment.tz.zone(this.user.profile.timezone)
    ) {
      return this.user.profile.timezone;
    } else if (
      this.account &&
      this.account.timezone &&
      moment.tz.zone(this.account.timezone)
    ) {
      return this.account.timezone;
    }
    return moment.tz.guess();
  }

  public getAccountTheme(): string {
    return this.account?.customization?.theme;
  }

  getAccountFavicon(): string {
    return this.account?.customization?.favicon;
  }

  isPodMasterAccount(): boolean {
    return this.account?.getId() === POD_MASTER_ACCOUNT_ID;
  }

  isPodMasterAccountChild(): boolean {
    return this.account?.resellerId === POD_MASTER_ACCOUNT_ID;
  }

  // Authentication methods

  public getToken(): string {
    return localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
  }

  public login(username: string, password: string): Observable<ILoginResult> {
    return this.http
      .post<IAuthTokenResponse>(
        this.api.getUrlRoot() + '/auth/token',
        {
          username: username,
          password: password,
        },
        {
          observe: 'response',
        }
      )
      .pipe(
        map(response => {
          let { body } = response;

          if (response.status === 202) {
            body = body as I2faResponse;
            return {
              code: body.code,
              type: body.type,
              token: body.token,
            };
          } else {
            // login successful if there's a jwt token in the response
            const token = body.token;
            if (token) {
              // store jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);

              // return true to indicate successful login
              return {
                code: 'logged',
              };
            } else {
              // return false to indicate failed login
              throw new Error('Invalid token');
            }
          }
        })
      );
  }

  public logout(): void {
    // Clear localStorage to log user out
    localStorage.clear();
    // Clear vars in cache
    this.accountsMap = null;
    this.hasFavoritesSubjects = {};
    this.unreadNotificationsSubject = null;
    this.user = null;
    this.account = null;
    this.permissionsMap = null;
  }

  public resetPassword(email: string, captcha: string): Observable<any> {
    return this.http
      .post(this.api.getUrlRoot() + '/auth/recover-password', {
        email,
        captcha,
      })
      .pipe(catchError(error => this.api.catchError(error)));
  }

  public setPassword(password: string, token: string, captcha:string) {
    return this.http
      .post(this.api.getUrlRoot() + '/auth/reset/' + token, {
        password,
        captcha,
      })
      .pipe(catchError(error => this.api.catchError(error)));
  }

  public set2faValueByToken(type: I2faType, token: string, value: string) {
    return this.http
      .put<I2faResponse>(
        `${this.api.getUrlRoot()}/auth/2fa/set/${type}/${token}`,
        {
          value,
        }
      )
      .pipe(catchError(error => this.api.catchError(error)));
  }

  public set2faValueByUser(
    type: I2faType,
    value: string,
    userId: string,
    password?: string
  ) {
    return this.http
      .put<User>(`${this.api.getUrlRoot()}/auth/2fa/set/${type}`, {
        value,
        userId,
        password,
        accountId: this.account.getId(),
      })
      .pipe(
        map(data => new User(data)),
        catchError(error => this.api.catchError(error))
      );
  }

  public confirm2faCode(type: I2faType, code: string, token: string) {
    return this.http
      .post<IAuthTokenResponseLogged>(
        `${this.api.getUrlRoot()}/auth/2fa/confirm/${type}/${token}`,
        {
          code,
        }
      )
      .pipe(
        map(body => {
          // login successful if there's a jwt token in the response
          const token = body.token;
          if (token) {
            // store jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(AUTH_TOKEN_STORAGE_KEY, token);

            // return true to indicate successful login
            return true;
          } else {
            // return false to indicate failed login
            throw new Error('Invalid token');
          }
        }),
        catchError(error => this.api.catchError(error))
      );
  }

  public repeat2faCode(type: I2faType, token: string) {
    return this.http
      .post<I2faResponse>(
        `${this.api.getUrlRoot()}/auth/2fa/repeat/${type}/${token}`,
        {}
      )
      .pipe(catchError(error => this.api.catchError(error)));
  }

  public getAllAccounts(): Observable<Record<string, Account>> {
    if (this.account) {
      return this.api
        .getList(Account, {
          accountId: this.account.getId(),
          limit: 0,
        })
        .pipe(
          map((list: ApiList<Account>) => {
            this.accountsMap = {};
            list.forEach(item => {
              this.accountsMap[item.getId()] = item;
            });
            return this.accountsMap;
          })
        );
    }
    return throwError(() => new Error('Account Id not found'));
  }

  public getUserMe(): Observable<User> {
    if (this.user && this.account) {
      return of(<User>this.user);
    } else {
      return this.api.getItem(User, 'me').pipe(
        switchMap((user: User) => {
          this.user = user;
          if (
            !!this.user.profile.language &&
            this.useLangs.indexOf(this.user.profile.language) >= 0
          ) {
            this.translate.use(this.user.profile.language);
          }

          let currentAccountId = this.user.permissions[0].accountId;
          if (this.user.setup && this.user.setup.currentAccountId) {
            currentAccountId = this.user.setup.currentAccountId;
          }
          return this.api.getItem(Account, currentAccountId);
        }),
        switchMap(account => {
          this.account = account;

          return forkJoin([
            this.getPermissions(this.user.permissions[0]),
            this.getAllAccounts(),
          ]);
        }),
        map(() => {
          return this.user;
        }),
        catchError(error => this.api.catchError(error))
      );
    }
  }

  protected getPermissions(permission: {
    accountId: string;
    roles: Array<string>;
  }): Observable<Record<string, boolean>> {
    if (this.account) {
      let params: HttpParams = new HttpParams();
      params = params.set('accountId', this.account.getId());

      return this.http
        .get<Array<string>>(
          `${this.api.getUrlRoot()}/accounts/${this.account.getId()}/actions`,
          {
            params,
          }
        )
        .pipe(
          map(actions => {
            this.permissionsMap = actions.reduce<Record<string, boolean>>(
              (prev, action) => ({
                ...prev,
                [action]: true,
              }),
              {}
            );
            return this.permissionsMap;
          })
        );
    }
    return throwError(() => new Error('Account Id not found'));
  }

  public hasPermission(action: string): boolean {
    return !!this.permissionsMap && !!this.permissionsMap[action];
  }

  private getLastLocationStorageKey(): string {
    if (this.user) {
      return `${LAST_LOCATION_STORAGE_KEY}-${this.user?.getId()}`;
    }
    return null;
  }

  // Last location methods
  public saveLastLocation(event: RouterEvent) {
    const key = this.getLastLocationStorageKey();
    if (key) {
      localStorage.setItem(key, event.url);
      sessionStorage.setItem(key, event.url);
    }
  }

  public getLastLocation() {
    const key = this.getLastLocationStorageKey();
    if (!key) {
      return null;
    }
    return sessionStorage.getItem(key) || localStorage.getItem(key);
  }

  // Account methods

  public getAllUserAccounts(): Observable<Account[]> {
    return this.api
      .getList(Account, {
        accountId: this.account.getId(),
        limit: 0,
      })
      .pipe(
        map((list: ApiList<Account>) => {
          this.cmAccountsMap = list;
          return this.cmAccountsMap;
        })
      );
  }

  public getAllUserAccountsTrees(): Observable<Array<IAccountTreeItem>> {
    const promises: Array<Observable<IAccountTreeItem>> = [];

    if (this.user) {
      (this.user.permissions || []).forEach(permission => {
        const promise = this.http.get<IAccountTreeItem>(
          this.api.getUrlRoot() + new Account().getResourcePath(),
          {
            params: {
              accountId: permission.accountId,
              format: 'tree',
            },
          }
        );

        promises.push(promise);
      });
    }

    return forkJoin(promises);
  }

  public getAccountById(accountId: string): Account {
    return this.accountsMap && this.accountsMap[accountId];
  }

  public changeCurrentAccount(id: string): Observable<User> {
    if (this.user) {
      if (!this.user.setup) {
        this.user.setup = {};
      }
      this.user.setup.currentAccountId = id;

      return this.api.put(
        User,
        this.user,
        { customization: this.user.setup },
        'customization'
      );
    } else {
      return of(this.user);
    }
  }

  public setFavorite(type: UserFavoriteType, id: string) {
    if (this.user && !!this.user.favoritesMap) {
      if (!this.user.favoritesMap[type]) {
        this.user.favoritesMap[type] = {};
      }

      if (this.user.favoritesMap[type][id]) {
        delete this.user.favoritesMap[type][id];
      } else {
        this.user.favoritesMap[type][id] = true;
      }
      this.user.favorites[type] = Object.keys(this.user.favoritesMap[type]);

      this.api
        .put(User, this.user, this.user.favorites, 'favorites')
        .subscribe(() => {
          if (this.hasFavoritesSubjects[type]) {
            this.hasFavoritesSubjects[type].next(this.hasFavorites(type));
          }
        });
    }
  }

  public getHasFavoritesObserver(type: UserFavoriteType): Observable<boolean> {
    if (!this.hasFavoritesSubjects[type]) {
      this.hasFavoritesSubjects[type] = new Subject<boolean>();
    }

    return this.hasFavoritesSubjects[type].asObservable();
  }

  public hasFavorites(type: UserFavoriteType): boolean {
    return (
      !!this.user &&
      !!this.user.favoritesMap &&
      !!this.user.favoritesMap[type] &&
      !!Object.keys(this.user.favoritesMap[type]).length
    );
  }

  public isFavorite(type: UserFavoriteType, id: string): boolean {
    return (
      !!this.user &&
      !!this.user.favoritesMap &&
      !!this.user.favoritesMap[type] &&
      !!this.user.favoritesMap[type][id]
    );
  }

  public setTableColumns(tableName: string, selectedFields: Array<string>) {
    if (this.user) {
      if (!this.user.setup) {
        this.user.setup = {};
      }
      if (!this.user.setup[tableName]) {
        this.user.setup[tableName] = {};
      }
      this.user.setup[tableName].columns = selectedFields;

      this.api
        .put(
          User,
          this.user,
          { customization: this.user.setup },
          'customization'
        )
        .subscribe();
    }
  }

  public getTableColumns(tableName: string): Array<string> {
    return (
      !!this.user &&
      !!this.user.setup &&
      this.user.setup[tableName] &&
      this.user.setup[tableName].columns
    );
  }

  public setTableSort(
    tableName: string,
    sort: { field: string; order: 'asc' | 'desc' }
  ) {
    if (this.user) {
      if (!this.user.setup) {
        this.user.setup = {};
      }
      if (!this.user.setup[tableName]) {
        this.user.setup[tableName] = {};
      }
      this.user.setup[tableName].sort = sort;

      this.api
        .put(
          User,
          this.user,
          { customization: this.user.setup },
          'customization'
        )
        .subscribe();
    }
  }

  public getTableSort(tableName: string): {
    field: string;
    order: 'asc' | 'desc';
  } {
    return (
      !!this.user &&
      !!this.user.setup &&
      this.user.setup[tableName] &&
      this.user.setup[tableName].sort
    );
  }

  public setAnalyticsCharts(
    charts: Array<{ key: string; collapsed: boolean }>
  ) {
    if (this.user) {
      if (!this.user.setup) {
        this.user.setup = {};
      }
      if (!this.user.setup.analyticsCharts) {
        this.user.setup.analyticsCharts = {};
      }
      this.user.setup.analyticsCharts = charts;

      this.api
        .put(
          User,
          this.user,
          { customization: this.user.setup },
          'customization'
        )
        .subscribe();
    }
  }

  public setDashboardWidgets(
    widgets: Array<{ key: string; collapsed: boolean }>
  ) {
    if (this.user) {
      if (!this.user.setup) {
        this.user.setup = {};
      }
      if (!this.user.setup.dashboardWidgets) {
        this.user.setup.dashboardWidgets = {};
      }

      this.user.setup.dashboardWidgets = widgets;

      this.api
        .put(
          User,
          this.user,
          { customization: this.user.setup },
          'customization'
        )
        .subscribe();
    }
  }

  public isAlertRead(alertKey: string) {
    return (
      this.user &&
      this.user.setup &&
      this.user.setup.readAlerts &&
      this.user.setup.readAlerts[alertKey]
    );
  }

  public readAlert(alertKey: string) {
    if (this.user) {
      if (!this.user.setup) {
        this.user.setup = {};
      }
      if (!this.user.setup.readAlerts) {
        this.user.setup.readAlerts = {};
      }

      this.user.setup.readAlerts[alertKey] = true;

      this.api
        .put(
          User,
          this.user,
          { customization: this.user.setup },
          'customization'
        )
        .subscribe();
    }
  }

  public getAnalyticsCharts(): Array<{ key: string; collapsed: boolean }> {
    return !!this.user && !!this.user.setup && this.user.setup.analyticsCharts;
  }

  public getDashboardWidgets() {
    return !!this.user && !!this.user.setup && this.user.setup.dashboardWidgets;
  }

  public getAccountCharts(): Array<string> {
    return !!this.account && this.account.charts;
  }

  // This method is just for debug
  public cleanSetup() {
    this.api
      .put(User, this.user, { customization: {} }, 'customization')
      .subscribe();
  }

  // Notifications method
  protected _getUnreadNotifications(
    limit = 5
  ): Observable<ApiList<Notification>> {
    return this.api
      .getList(Notification, {
        limit,
        read: false,
        sort: 'created',
        order: 'desc',
      })
      .pipe(
        map(notifications => {
          if (this.unreadNotificationsSubject) {
            this.unreadNotificationsSubject.next(notifications);
          }
          return notifications;
        })
      );
  }

  public getUnreadNotifications(limit = 5): Observable<ApiList<Notification>> {
    if (!this.unreadNotificationsSubject) {
      this.unreadNotificationsSubject = new Subject<ApiList<Notification>>();
    }

    // Force to load notifications
    this._getUnreadNotifications(limit).subscribe();

    return this.unreadNotificationsSubject.asObservable();
  }

  public setNotificationRead(
    notification: Notification,
    read: boolean
  ): Observable<Notification> {
    return this.api.put(Notification, notification, { read: read }).pipe(
      map(notif => {
        this._getUnreadNotifications().subscribe();
        return notif;
      })
    );
  }

  public deleteNotification(
    notification: Notification
  ): Observable<Notification> {
    return this.api.delete(Notification, notification).pipe(
      map(notif => {
        this._getUnreadNotifications().subscribe();
        return notif;
      })
    );
  }

  public getLoginCustomization(): Observable<ILoginCustomization> {
    let params: HttpParams = new HttpParams();
    params = params.set('customURL', window.location.hostname);
    return this.http.get<ILoginCustomization>(
      this.api.getUrlRoot() + '/logincustomization',
      {
        params: params,
      }
    );
  }
}
