import { Injectable, Inject, InjectionToken, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { USE_API_HOST } from './api.service';
import { catchError, switchMap, retryWhen, tap } from 'rxjs/operators';
import { throwError, Observable, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AUTH_TOKEN_STORAGE_KEY } from './session.service';

export const USE_TOKEN_HEADER = new InjectionToken<string>('USE_TOKEN_HEADER');
export const USE_INTERCEPTOR_401_EXCLUSION = new InjectionToken<string>(
  'USE_INTERCEPTOR_401_EXCLUSION'
);
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  constructor(
    @Inject(USE_API_HOST) private useApiHost: string,
    @Inject(USE_TOKEN_HEADER) private useTokenHeader: string = 'x-access-token',
    @Inject(USE_INTERCEPTOR_401_EXCLUSION)
    private useInterceptor401Exclusion: string = '/token$',
    @Inject(Injector) private injector: Injector
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    const setToken = request.url.startsWith(this.useApiHost);

    if (setToken && token) {
      const headers = {};
      headers[this.useTokenHeader] = token;

      request = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          // Unauthorized
          if (err.status === 401) {
            localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);

            if (!err.url.match(this.useInterceptor401Exclusion)) {
              window.location.href = '/';
            }
          }
          // Bad Gateway
          if (err.status === 502) {
            window.location.href = '/maintenance';
          }
        }
        return throwError(err);
      }),
      retryWhen(errors =>
        errors.pipe(
          switchMap((err, retryAttempt) => {
            // Status 0 is usually for CORS,
            // but we are getting it also on timeouts
            if ([0, 504].includes(err.status)) {
              return timer(Math.pow(2, retryAttempt + 1) * 1000).pipe(
                tap(() => this.toastrService.info('Retrying request'))
              );
            }
            return throwError(err);
          })
        )
      )
    );
  }
}
