import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { Account, MyFile, Serializable, User } from '../models';
import { SessionService, TOKEN_QUERY_KEY } from './session.service';

export const USE_API_HOST = new InjectionToken<string>('USE_API_HOST');
export const USE_ACCOUNT_ID = new InjectionToken<boolean>('USE_ACCOUNT');

export interface ApiList<T extends Serializable> extends Array<T> {
  $totalCount?: number;
  $totalPages?: number;
  $hasMore?: boolean;
}

export enum RequestType {
  INC = 'INC',
  SRQ = 'SRQ',
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private requestTypeEndpoints: Record<RequestType, string> = {
    [RequestType.INC]: '/support/snow/ticket',
    [RequestType.SRQ]: '/support/snow/request',
  };

  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  constructor(
    protected httpClient: HttpClient,
    private session: SessionService,
    @Inject(Injector) private injector: Injector,
    @Inject(USE_API_HOST) protected useApiHost: string,
    @Inject(USE_ACCOUNT_ID) protected useAccountId: boolean = true
  ) {}

  // Tools
  public getUrlRoot(): string {
    return this.useApiHost;
  }

  public catchError(error: HttpErrorResponse | string) {
    if (error instanceof HttpErrorResponse) {
      const errString = error.error && error.error.message;
      if (errString) {
        this.toastrService.error(errString);
      }
    } else {
      this.toastrService.error(error);
    }

    return throwError(error);
  }

  // GET methods
  public getList<T extends Serializable>(
    t: new ({}) => T,
    searchParams: Record<string, any> = {},
    action: string = ''
  ): Observable<ApiList<T>> {
    const path = t.prototype.getResourcePath();

    let params: HttpParams = new HttpParams();
    Object.keys(searchParams).forEach(key => {
      params = params.set(key, searchParams[key]);
    });

    if (!searchParams?.accountId && this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }

    return this.httpClient
      .get<Array<T>>(this.useApiHost + path + (action ? '/' + action : ''), {
        params: params,
        observe: 'response',
      })
      .pipe(
        map(response => {
          const result: ApiList<T> = <ApiList<T>>(
            response.body.map(object => new t(object))
          );
          result.$totalCount = +(response.headers.get('x-total-count') || 0);
          result.$totalPages = +(response.headers.get('x-total-pages') || 1);
          if (response.headers.get('x-total-more')) {
            result.$hasMore = response.headers.get('x-total-more') === 'true';
          }
          return result;
        }),
        catchError(error => this.catchError(error))
      );
  }

  public getListCsvUrl<T extends Serializable>(
    t: new ({}) => T,
    searchParams: any = {},
    action: string = ''
  ): string {
    const path = t.prototype.getExportPath();

    let params: HttpParams = new HttpParams();
    Object.keys(searchParams || {}).forEach(key => {
      params = params.set(key, searchParams[key]);
    });

    if (!searchParams?.accountId && this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }
    params = params
      .set('format', 'csv')
      .set(TOKEN_QUERY_KEY, this.session.getToken());

    return (
      this.useApiHost +
      path +
      (action ? '/' + action : '') +
      '?' +
      params.toString()
    );
  }

  public postAsForm(url: string, params: any = {}, method: string = 'post') {
    const form = document.createElement('form');
    form.method = method;
    form.action = url;
    form.target = '_blank';
    Object.keys(params).forEach(key => {
      const field = document.createElement('input');
      field.type = 'hidden';
      field.name = key;
      field.value = params[key];
      form.appendChild(field);
    });
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  public getBulkList<T extends Serializable>(
    t: new ({}) => T,
    path: string,
    searchParams: any = {},
    body: any
  ): Observable<ApiList<T>> {
    let params: HttpParams = new HttpParams();
    Object.keys(searchParams).forEach(key => {
      params = params.set(key, searchParams[key]);
    });

    if (!searchParams?.accountId && this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }

    return this.httpClient
      .post<Array<T>>(this.useApiHost + path, body, {
        params: params,
        observe: 'response',
      })
      .pipe(
        map(response => {
          const result: ApiList<T> = <ApiList<T>>(
            response.body.map(object => new t(object))
          );
          result.$totalCount = +response.headers.get('x-total-count');
          result.$totalPages = +response.headers.get('x-total-pages');
          if (response.headers.get('x-total-more')) {
            result.$hasMore = response.headers.get('x-total-more') === 'true';
          }
          return result;
        })
      );
  }

  public getItemActionUrl<T extends Serializable>(
    t: new ({}) => T,
    item: T,
    action: string = ''
  ): string {
    const path = t.prototype.getResourcePath();

    let params: HttpParams = new HttpParams();

    if (this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }

    params = params.set(TOKEN_QUERY_KEY, this.session.getToken());

    return (
      this.useApiHost +
      path +
      '/' +
      item.getId() +
      (action ? '/' + action : '') +
      '?' +
      params.toString()
    );
  }

  protected getItemData<T extends Serializable, P = any>(
    t: new ({}) => T,
    id: string,
    action?: string,
    searchParams: Record<string, string | number | boolean> = {}
  ): Observable<P> {
    const path = t.prototype.getResourcePath();

    let params: HttpParams = new HttpParams();
    Object.keys(searchParams).forEach(key => {
      params = params.set(key, searchParams[key]);
    });

    if (!searchParams?.accountId && this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }

    return this.httpClient.get<P>(
      this.useApiHost +
        path +
        (!!id ? '/' + id : '') +
        (!!action ? '/' + action : ''),
      {
        params: params,
      }
    );
  }

  public getItem<T extends Serializable>(
    t: new ({}) => T,
    id?: string,
    params: Record<string, any> = {}
  ): Observable<T> {
    return this.getItemData(t, id, null, params).pipe(
      map(data => {
        const item = new t(data);
        // Update current user instance and return if is the item
        if (
          this.session.user &&
          item instanceof User &&
          item.getId() === this.session.user.getId()
        ) {
          this.session.user.init(data);
          return this.session.user as unknown as T;
        }
        // Update account in map
        if (
          this.session.accountsMap &&
          item instanceof Account &&
          this.session.accountsMap[item.getId()]
        ) {
          this.session.accountsMap[item.getId()].init(data);
          return this.session.accountsMap[item.getId()] as unknown as T;
        }
        return item;
      }),
      catchError(error => this.catchError(error))
    );
  }

  public getItemChild<T extends Serializable, P = any>(
    t: new ({}) => T,
    id: string,
    action,
    searchParams: Record<string, string | number | boolean> = {}
  ): Observable<P> {
    return this.getItemData<T, P>(t, id, action, searchParams).pipe(
      catchError(error => this.catchError(error))
    );
  }

  public getSchema<T>(type: string, searchParams: any = {}): Observable<T> {
    const path = '/schema/' + type;

    let params: HttpParams = new HttpParams();
    Object.keys(searchParams).forEach(key => {
      params = params.set(key, searchParams[key]);
    });

    if (!searchParams?.accountId && this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }

    return this.httpClient.get<T>(this.useApiHost + path, {
      params,
    });
  }

  // CRUD methods
  public put<T extends Serializable>(
    t: new ({}) => T,
    object: T,
    body: any,
    action: string = null
  ): Observable<T> {
    body = body || {};

    if (!body.accountId && this.useAccountId && this.session.account) {
      body.accountId = this.session.account.getId();
    }

    return this.httpClient
      .put<any>(
        this.useApiHost +
          object.getResourcePath() +
          (object['materialNumber']
            ? '/' + object['materialNumber']
            : object.getId()
            ? '/' + object.getId()
            : '') +
          (action ? '/' + action : ''),
        body
      )
      .pipe(
        flatMap(data => {
          if (data && data._id) {
            return of(data);
          } else if (object && object.getId) {
            return this.getItemData(t, object.getId());
          } else {
            return of(null);
          }
        }),
        map(data => {
          object.init(data);
          return object;
        }),
        catchError(error => this.catchError(error))
      );
  }

  public putRaw<T extends Serializable, K extends Serializable>(
    t: new ({}) => T,
    object: T,
    body: any,
    action: string = null
  ): Observable<K> {
    body = body || {};

    if (!body.accountId && this.useAccountId && this.session.account) {
      body.accountId = this.session.account.getId();
    }

    return this.httpClient
      .put<any>(
        this.useApiHost +
          object.getResourcePath() +
          (object['materialNumber']
            ? '/' + object['materialNumber']
            : object.getId()
            ? '/' + object.getId()
            : '') +
          (action ? '/' + action : ''),
        body
      )
      .pipe(catchError(error => this.catchError(error)));
  }

  public post<T extends Serializable>(
    t: new ({}) => T,
    object: T,
    body: any,
    action: string = null
  ): Observable<T> {
    body = body || {};
    if (!body.accountId && this.useAccountId && this.session.account) {
      body.accountId = this.session.account.getId();
    }

    return this.httpClient
      .post<any>(
        this.useApiHost +
          t.prototype.getResourcePath() +
          (object && object.getId() ? '/' + object.getId() : '') +
          (action ? '/' + action : ''),
        body
      )
      .pipe(
        flatMap(data => {
          if (data && data._id) {
            return of(data);
          } else if (object && object.getId) {
            return this.getItemData(t, object.getId());
          } else {
            return of(null);
          }
        }),
        map(data => {
          if (object) {
            object.init(data);
            return object;
          } else if (data) {
            const item = new t(data);
            // Refresh cache of accounts if we have POST a new account
            if (
              this.useAccountId &&
              item instanceof Account &&
              !this.session.accountsMap[item.getId()]
            ) {
              this.session.getAllAccounts().subscribe();
            }
            return item;
          } else {
            return object;
          }
        }),
        catchError(error => this.catchError(error))
      );
  }

  public patch<T extends Serializable>(t: new ({}) => T, object: T, body: any) {
    body = body || {};
    if (!body.accountId && this.useAccountId && this.session.account) {
      body.accountId = this.session.account.getId();
    }

    return this.httpClient
      .patch<any>(
        this.useApiHost + t.prototype.getResourcePath() + '/' + object.getId(),
        body
      )
      .pipe(
        map(data => {
          object.init(data);
          return object;
        }),
        catchError(error => this.catchError(error))
      );
  }

  public delete<T extends Serializable>(
    t: new ({}) => T,
    object: T,
    action: string = null,
    params: Record<string, any> = {}
  ): Observable<T> {
    if (!params.accountId && this.useAccountId && this.session.account) {
      params.accountId = this.session.account.getId();
    }

    const options = {
      params: params,
    };

    return this.httpClient
      .delete(
        this.useApiHost +
          object.getResourcePath() +
          '/' +
          (object['materialNumber']
            ? object['materialNumber']
            : object.getId()) +
          (action ? '/' + action : ''),
        options
      )
      .pipe(
        map((data: any) => {
          if (data && data._id) {
            object.init(data);
          }
          return object;
        }),
        catchError(error => this.catchError(error))
      );
  }

  public putBulk<T extends Serializable>(
    t: new ({}) => T,
    data: any,
    searchParamsQuery: any = {},
    searchParamsBody: any = {},
    action: string = null
  ): Observable<any> {
    const body = {
      ...searchParamsBody,
      data,
    };

    if (
      !searchParamsQuery.accountId &&
      !searchParamsBody.accountId &&
      this.useAccountId &&
      this.session.account
    ) {
      searchParamsQuery.accountId = this.session.account.getId();
    }

    return this.httpClient
      .put<any>(
        this.useApiHost +
          '/bulk' +
          t.prototype.getResourcePath() +
          (action ? '/' + action : ''),
        body,
        {
          params: searchParamsQuery,
        }
      )
      .pipe(
        map(result => {
          return result;
        }),
        catchError(error => this.catchError(error))
      );
  }

  public postBulk<T extends Serializable>(
    t: new ({}) => T,
    data: any,
    searchParamsQuery: any = {},
    searchParamsBody: any = {},
    action: string = null
  ): Observable<any> {
    const body = {
      ...searchParamsBody,
      data,
    };

    if (
      !searchParamsQuery.accountId &&
      !searchParamsBody.accountId &&
      this.useAccountId &&
      this.session.account
    ) {
      searchParamsQuery.accountId = this.session.account.getId();
    }

    return this.httpClient
      .post<any>(
        this.useApiHost +
          '/bulk' +
          t.prototype.getResourcePath() +
          (action ? '/' + action : ''),
        body,
        {
          params: searchParamsQuery,
        }
      )
      .pipe(catchError(error => this.catchError(error)));
  }

  public postAction<T extends Serializable, S = any>(
    t: new ({}) => T,
    object: T,
    body: any,
    action: string
  ): Observable<S> {
    body = body || {};
    if (!body.accountId && this.useAccountId && this.session.account) {
      body.accountId = this.session.account.getId();
    }

    return this.httpClient
      .post<S>(
        this.useApiHost +
          t.prototype.getResourcePath() +
          (object && object.getId() ? '/' + object.getId() : '') +
          (action ? '/' + action : ''),
        body
      )
      .pipe(catchError(error => this.catchError(error)));
  }

  public activateZTP(): Observable<any> {
    return this.httpClient
      .put(this.useApiHost + '/iot/device/ztp', {
        accountId:
          this.useAccountId && this.session.account
            ? this.session.account.getId()
            : undefined,
        body: '',
      })
      .pipe(
        map((data: { connioAccountId: string }) => {
          this.session.account.ztp.connioAccountId = data.connioAccountId;
          return data;
        }),

        catchError(error => this.catchError(error))
      );
  }

  public discoverDevices(): Observable<any> {
    return this.httpClient
      .post(this.useApiHost + '/iot/discover', {
        accountId:
          this.useAccountId && this.session.account
            ? this.session.account.getId()
            : undefined,
        body: '',
      })
      .pipe(
        map(data => {
          return data;
        }),

        catchError(error => this.catchError(error))
      );
  }

  public getSubAccount(): Observable<any> {
    let params: HttpParams = new HttpParams();

    if (this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }
    return this.httpClient
      .get(this.useApiHost + '/iot/getAccountCN', {
        params: params,
      })
      .pipe(
        map(data => {
          return data;
        }),

        catchError(error => this.catchError(error))
      );
  }

  // Utils methods
  public postMail(
    type: 'contact' | 'support' | 'order',
    subject: string,
    body: string,
    sendcopy: boolean = false
  ): Observable<any> {
    return this.httpClient
      .post(this.useApiHost + '/mail', {
        accountId:
          this.useAccountId && this.session.account
            ? this.session.account.getId()
            : undefined,
        subject: subject,
        body: body,
        type: type,
        sendcopy: sendcopy,
      })
      .pipe(catchError(error => this.catchError(error)));
  }

  public ticketSupport(
    body: FormData,
    requestType: RequestType
  ): Observable<any> {
    if (!body.get('accountId') && this.useAccountId && this.session.account) {
      body.append('accountId', this.session.account.getId());
    }

    return this.httpClient
      .post(this.useApiHost + this.requestTypeEndpoints[requestType], body)
      .pipe(catchError(error => this.catchError(error)));
  }

  public uploadFile(file: File, ownership = false): Observable<MyFile> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    if (this.useAccountId && this.session.account) {
      formData.append('accountId', this.session.account.getId());
    }
    // Saving with ownership to avoid another accounts access
    if (ownership) {
      formData.append('ownership', 'true');
    }

    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');

    return this.httpClient
      .post<MyFile>(this.useApiHost + '/upload', formData, {
        headers: headers,
      })
      .pipe(
        map(data => new MyFile(data)),
        catchError(error => this.catchError(error))
      );
  }

  public getGraphData(id: string, queryParams: any) {
    let params: HttpParams = new HttpParams();
    Object.keys(queryParams).forEach(key => {
      params = params.set(key, queryParams[key]);
    });
    if (!queryParams.accountId && this.useAccountId && this.session.account) {
      params = params.set('accountId', this.session.account.getId());
    }

    return this.httpClient.get(this.useApiHost + '/graph/' + id, {
      params: params,
    });
  }

  public postWebhook(type: string, data: any): Observable<any> {
    return this.httpClient
      .post(this.useApiHost + '/webhook', {
        accountId:
          this.useAccountId && this.session.account
            ? this.session.account.getId()
            : undefined,
        type,
        data,
      })
      .pipe(catchError(error => this.catchError(error)));
  }
}
